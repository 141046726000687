<template>
  <div class="col-12" style="
    z-index:999; 
    height: 100%;
    display: inline-block;
    position: absolute;
    background: white;
    left: 0;" 
    >
        <div style="height:100%;">
      <span class="closeBtn" v-on:click="hideLASEditor()" > X </span>   
            <iframe id="lasEditor" style="width: 100%; height: 100%;" :src="laseditorpath"></iframe>
    </div>
</div>

</template>

<script lang="js" >

export default {
  name: "LASEditor" ,
    components: {  } ,
    props: [ "laseditorpath" ],
  data()  {
          return {
          }  ;
  }
  , beforeUnmounte: function (){
  }
  ,  mounted  :async function(  ){  
  
  
     },
  methods:{  
  hideLASEditor(){
   //   const iframe = window.document.getElementById('lasEditor');
   //   iframe.contentWindow.location.reload();

      this.$emit("show-laseditor", {path:false});
  }
    }
}

</script>

<style>
label > input {
  display: none;
}

.over {
 background: rgba(0,0,0,0.1);
}

label {
  padding: 0 1rem;
} 

label::after {
  content: '+';
  font-size: 1rem;
  color: #888;
  padding-left: 1rem;
}

body{
    
    background-color:transparent !important;
}

#three{
    background:black;
}

@media (max-width: 150px) {
    .lil-gui{
        display:none !important;
    }
    #three{
        background:rgba(0,0,0,0.7);
    }
}

@media (max-height: 300px) {
    .lil-gui{
        
        display:none !important;
    }
      #three{
          
        background:rgba(0,0,0,0.7);
    }
}
.closeBtn{
  color:gray;
  font-size : 32px; 
  position: absolute;
  right: 4%;
  margin-top: 1%;
  cursor: pointer;
}


</style>
