<template>
  <div class="col-12" style="
    z-index:999; 
    height: 100%;
    display: inline-block;
    position: absolute;
    background: white;
    left: 0;" 
    >
    
    <div style="height:100%;">
      <span class="closeBtn" v-on:click="hidePotree()" > X </span>   
            <iframe id="potree" style="width: 100%; height: 100%;" :src="selectedUrl"></iframe>
    </div>
</div>

</template>

<script lang="js" >
import { faCamera ,faFile  } from "@fortawesome/free-solid-svg-icons";
import { db } from '../../modules/app/indexeddb'; 

export default {
  name: "PotreeViewer" ,
    components: {  } ,
    props: [ "potreepath" ],
  data()  {
          return {
              selectedUrl : "/tools/view/potree/ui/las.html" , 
              faCamera : faCamera , 
              faFile : faFile ,
              
         }  ;
  }
  , beforeUnmounte: function (){
  }
  ,  mounted  :async function(  ){  
  
     this.sendData();
  
     },
  watch: {
    
       potreepath : function(newHoge, oldHoge){
           this.sendData();
       }
    }
  ,methods:{  
  hidePotree(){
      const iframe = window.document.getElementById('potree');
      iframe.contentWindow.location.reload();

      this.$emit("show-potree", {path:false});
  },
  async getBlobByFileURL( url ){

      this.$emit('show-flash', true);    

      var mblob;
       try{
            const cacheAPI = await caches.open('potree-cache');
           const r = await cacheAPI.match( url );
          
            if ( r ) { 
                const  a = await r.arrayBuffer();
                mblob = new Blob([a]);
            }
            if ( !mblob ){
         
            const res = await fetch( url );
            
            if(res.status === 200 ){
                 const  marr = await res.arrayBuffer();
                 mblob = new Blob([marr]);
                 try{
                 await cacheAPI.put( url , new Response( mblob  ));
                 } catch (e){console.log(e); }
            }            
        }
    }catch(e){console.log(e);}
      this.$emit('show-flash', false);
      
      const res = await fetch( url );
            
            if(res.status === 200 ){
                 const  marr = await res.arrayBuffer();
                 mblob = new Blob([marr]);
            }
            
        return mblob; 
  },
  async sendData(){
     //  window.document.getElementById('potree').contentWindow.location.reload();
          const iframe = window.document.getElementById('potree');
            
            try{
                
            const mblob = await this.getBlobByFileURL(  "/tools/file/" +this.potreepath +"/metadata.json" ); 
            
            const  mpath = URL.createObjectURL( mblob ) ;
            
            const oblob = await this.getBlobByFileURL(  "/tools/file/" +this.potreepath +"/octree.bin" ); 
            const opath = URL.createObjectURL( oblob ) ;
              
            const hblob = await this.getBlobByFileURL(  "/tools/file/" +this.potreepath +"/hierarchy.bin" ) ;
            const hpath = URL.createObjectURL( hblob );

        
          const o = await iframe.contentWindow.sendData(
          {
               mpath : mpath ,
               opath : opath ,
               hpath : hpath ,
               title :this.potreepath
          });

         /*
          URL.revokeObjectURL(metapath);     
          URL.revokeObjectURL(hpath);     
          URL.revokeObjectURL(opath);
		
         */
          }catch (e){
              console.log(e);
          }
        },
         onDrag (state){
             this.isDragOver = state;
         },
        async onDrop(e){
           
           /*
             const file = e.dataTransfer.files[0] ;
              this.uploadFileName = file.name;
             try{
             if ( 100000000 < file.size ){
                
                   this.emit('show-flash',{ "message": "1ファイルのサイズは50MB以下でお願いい致します。","type": "warning"});  
                  
                 return ;
             }
             let formData = new FormData();
            
             formData.append("file", file, 'projects/pointcloud/' + this.$route.params.projectname  + '/' + this.project.title  + '/folder/'  +  file.name );
             
            const res = await fetch( process.env.VUE_APP_API_URL+'/FileUploadHttpTrigger/'+process.env.VUE_APP_API_CODE , {method: "POST", body: formData});
            
            const text = await res.text();
            if (text == "error0"){
                
                   this.$emit('show-flash',{ "message": "アップロードしました。","type": "success"}); 
                   this.uploadFileName = "ファイルのアップロード" ; 
            } else {
                  this.showFolder();
                   this.$emit('show-flash',{ "message": "ファイルのアップロードに失敗しました。","type": "warning"});  
            }
            
            }catch (e){
            
                this.$emit('show-flash',{ "message": "ファイルのアップロードに失敗しました。","type": "warning"});  
               console.log(e) ; 
            }
            */
         },
         async showFolder (){
             
          //   alert("projects/pointcloud/"+ this.$route.params.projectname +"/" +this.project.title+"/&flat=false");
             try {
            var res  ;
            var r = "projects/pointcloud/"+ this.$route.params.projectname +"/" +this.project.title+"/&flat=false"
            var code = "";
            if(process.env.VUE_APP_API_CODE =="" ){
               code = "?";
            } else {
               code = process.env.VUE_APP_API_CODE + "&";
            }  
            res = await fetch (process.env.VUE_APP_API_URL +"GetFileByHierarchyV2" +  code + r  );    
        
         const json = await res.json();   
         
         this.projectNames =  json.filenames;
         
         const fs =[];
         for ( var  k=0 ;  k < json.filenames.length ;  k++ ){
             if(k >=this.bulkDispNum ){ break; }
          
             fs.push ( json.filenames[k] ) ;
         }
         
         const metas = await fetch (process.env.VUE_APP_API_URL + "BulkGetFolderMetaV2" +process.env.VUE_APP_API_CODE  , {
                  method : "POST",
                  headers: {  'Content-Type': 'application/json' },
                  body :JSON.stringify( { "filenames" : fs } )
            });        
            
        const projects =await metas.json()
        var i=0;
        this.showingProjects = []; 
        for  ( var key in projects ){
            if(  i >= this.bulkDispNum ){
                   break;
            } 
            this.showingProjects.push( projects[key] );
            i++;
            } 
        }catch (e) {
             
             console.log( e );
         }
  
         },
         async updateProject (project) {
             this.editMode= false ;
             if ( confirm("編集内容を保存しますか？") ){
                try{
                let res = await fetch (process.env.VUE_APP_API_URL + 'CreateOrUpdateFolderV2'+process.env.VUE_APP_API_CODE ,
                 {
                  method : "POST",
                  headers: {  'Content-Type': 'application/json' },
                  body :JSON.stringify( {
                     path :  project.path ,
                     info : {
                        type : project.type ,
                        title : project.title ,
                        description : project.description ,  
                        created_at : project.created_at
                    }
                  } )
                } );      
             
             const json =await res.json();
              if(json.error===0){
                  
                  this.$emit('show-flash',{ "message": "更新しました。","type": "success"});                     
              } else {
                    
                 this.$emit('show-flash',{ "message": "更新出来ませんでした。","type": "warning"});     
              }
            } catch(e){
                console.log(e) ;
                 this.$emit('show-flash',{ "message": "更新出来ませんでした。","type": "warning"});     
            }
                    
            }
               this.showFolder(); 
                         
         } ,
         async deleteProject ( project ){
           //  alert( JSON.stringify(project) );
             if ( confirm("本当に削除しますか？") ) {
              try{
                    project.showSubMenu = false ;
               const res= await fetch (process.env.VUE_APP_API_URL + "DeleteFolderV2" +process.env.VUE_APP_API_CODE  , {
                  method : "DELETE",
                  headers: {  'Content-Type': 'application/json' },
                  body :JSON.stringify( { "path" : project.path } )
                });    
                const err = await res.json();
                if ( err.error === 0  ){
                    
                     this.$emit('show-flash',{ "message": project.title + "を削除しました。","type": "success"});     
                    this.showFolder();
                } else {
                     this.$emit('show-flash',{ "message": project.title + "の削除ができませんでした。。","type": "warning"});     
                }
                }catch (e){
                     this.$emit('show-flash',{ "message": project.title + "の削除ができませんでした。。","type": "warning"});   
                } 
          
            }
            
         },
         async showNextProjects ( pos  ){
            
            if (this.projectNames.length <=  this.showingProjects.length ){ return ; }
         
            var npos = this.bulkDispNum * ( pos +1 ) ; 
            npos = ( npos > this.projectNames.length ) ?  this.projectNames.length : npos; 
            const fs = [] ;
            for  ( var cpos = this.bulkDispNum * pos  ;   cpos < npos ; cpos++ ){
                if(  cpos >= npos ){
                   break;
                 } 
            
                fs.push( this.projectNames[cpos] );
            }
            
            const metas = await fetch (process.env.VUE_APP_API_URL + "BulkGetFolderMetaV2" +process.env.VUE_APP_API_CODE  , {
                  method : "POST",
                  headers: {  'Content-Type': 'application/json' },
                  body :JSON.stringify( { "filenames" : fs } )
            });        
          
          const projects = await metas.json();
          // this.showingProjects = []; 
          var i =0;
          for  ( var key in projects ){
            
            if(  i >= this.bulkDispNum ){ break; }        
             
            this.showingProjects.push( projects[key] );
            i++ ;
        }     
             
    },
         moment (text){
           
           return moment(text);  
         },
          async addProject (){
     
            try{
                
                let res = await fetch (process.env.VUE_APP_API_URL + 'CreateFolderV2'+process.env.VUE_APP_API_CODE ,
                 {
                  method : "POST",
                  headers: {  'Content-Type': 'application/json' },
                  body :JSON.stringify( {
                     path :  "projects/pointcloud/" + this.$route.params.foldername + "/" + this.addingProject.title ,
                     info : {
                        type : this.addingProject.type  ,
                        title : this.addingProject.title ,
                        description : this.addingProject.description 
                    } } )
                } );      
                let resp = await res.json();
                if(resp.error === 0){
                    
                    this.$emit('show-flash',{ "message":"プロジェクトを追加しました。","type": "success"});     
                    this.showFolder();
                    this.showAddProjectForm = false ;
                } else if (resp.error === 1) {
                       this.$emit('show-flash',{ "message":"プロジェクトのタイトルが存在します。","type": "warning"});     
                } else {
                    
                      this.$emit('show-flash',{ "message":"プロジェクトの追加に失敗しました。","type": "warning"});     
                }
            } catch (e){
                
                this.$emit('show-flash',{ "message":"プロジェクトの追加に失敗しました。","type": "warning"});     
            }
          }
    }
}

</script>

<style>
label > input {
  display: none;
}

.over {
 background: rgba(0,0,0,0.1);
}

label {
  padding: 0 1rem;
} 

label::after {
  content: '+';
  font-size: 1rem;
  color: #888;
  padding-left: 1rem;
}

body{
    
    background-color:transparent !important;
}

#three{
    background:black;
}

@media (max-width: 150px) {
    .lil-gui{
        display:none !important;
    }
    #three{
        background:rgba(0,0,0,0.7);
    }
}

@media (max-height: 300px) {
    .lil-gui{
        
        display:none !important;
    }
      #three{
          
        background:rgba(0,0,0,0.7);
    }
}
.closeBtn{
  color:gray;
  font-size : 32px; 
  position: absolute;
  right: 4%;
  margin-top: 1%;
  cursor: pointer;
}


</style>
