<template>
    <div>
    <div class="col-12" style="z-index: 9999999; position: absolute;  top: 20px;">
       <span class="closeBtn" v-on:click="hideLiDAR()" > X </span>   
    </div>
    <h3 style="background: #F5F5F5; width: 98%; margin: 1% !important; display:inline-block;">    
    <span>
    {{ statusMessage }}
    </span>
      <div v-show="!isLoaded" id="loadingview" style="
    display: inline-block;
    z-index: 99999;">

        <div style="inline-flex" >  
            <div :class="loaderStyleClass" class="dot" ></div>
        </div>
</div></h3>

    
     <iframe :src="lasViewerUrl" class="card col-6 float-start"  id="three"  style=" height: 41vh;"></iframe>

     <div class="card col-6 float-start"  style="height: 41vh; display: inline-block;" >
    
    <div class="col-6 float-start" style=" display: inline-block;">
  <br>
     <b> GNSS</b>  <br>

     <span>gnssTime :<span style="width:200px; display:inline-block;">{{ (gnss)?  gnss.header.stamp.sec : ""  }}.{{ (gnss)?  gnss.header.stamp.nanosec : ""  }}  </span> </span>  <br>
     <span>latitude: <span style="width:200px; display:inline-block;">{{ (gnss)?  gnss.latitude : ""  }}</span> </span>  <br>
     <span>longitude:<span style="width:200px; display:inline-block;"> {{ (gnss)?  gnss.longitude : ""  }}  </span></span>  <br>
     <span> altitude: <span style="width:200px; display:inline-block;">{{ (gnss)?  gnss.altitude : ""  }}  </span></span>  <br>
      </div >
        <div class="col-6 float-start" style=" height:100%; display: inline-block;">
  
             <l-map ref="map" :zoom="zoom" :center="center" >
     
       <l-geo-json v-for="(geo,i) in geoJsonList" :key="i"  :geojson="geo.json" :options="geo.geojsonOptions" />      

          <l-tile-layer
     :url="mapTileUrl"
      ></l-tile-layer>
     <l-control-layers />
 </l-map>
    <br>
       <span>マップタイルの出典  </span>
       <input v-model="mapTileUrl" /> 
       
        </div>   
            </div>
                
        <div style=" height: 41vh;" class="card col-6 float-start" >
         <img :src="imgSrc" style="max-height:100%; width:min-cotent; margin:0px auto;">
         </div>
        
        <div class="card col-6 float-start" style="display: inline-block; height: 41vh;" >
          
          <div  class="card col-12 float-start" style=" padding:1%; height:60px;" >  
          <button class="btn btn-dark" style="height:100%;" v-on:click="operation('begin')"> 開始 </button>
          </div>
          
          <div class=" card col-6 float-start" style="padding:1%; height: 120px;" >  
          <h6>フレーム</h6>
          <input type="range" v-model="frameNum" />
           {{frameNum}}<br>
          <button class="btn btn-dark" min="10" max="100" v-on:click="operation('frame_count='+frameNum)"> 設定 </button>
          </div>
          
            <div  class="card col-6 float-start" style="padding:1%; height: 120px; display:inline-block;" >  
          <h6>ステップ</h6>
          <input   class="form-control"  type="number" v-model="stepDegree" v-bind:disabled="true" />
          </div>
       
          
           <div  class=" card col-6 float-start" style="padding:1%; height: 120px; display: inline-block;" >  
                   <div class="col-6 float-start" style="display: inline-block;">
          <h6>開始角度</h6>
          <input class="form-control" min="0" max="360" step="45" type="number" v-model="startDegree" v-on:change="operation('degree_begin='+startDegree)" />
</div>
                  <div class="col-6 float-start" style="display: inline-block;">
          <h6>終了角度</h6>
          
<input class="form-control" min="0" max="360"  step="45" type="number" v-model="endDegree" v-on:change="operation('degree_end='+endDegree)" />
</div>
                   
          </div>
          
          
           <div  class="card col-6 float-start" style="padding:1%; height: 60px;" >  
           <button class="btn btn-warning" style="height:100%;" v-on:click="operation('restart')">再起動</button>
           <br>
           <button class="btn btn-danger" style="height:100%;" v-on:click="operation('shutdown')">終了</button></div>
        </div>
      </div>
      
      
      
</template>

<script >

//import L from 'leaflet';
import "leaflet/dist/leaflet.css"

import L from 'leaflet';
import "leaflet/dist/leaflet.css"
import { LMap, LGeoJson , LTileLayer, LControlLayers } from "@vue-leaflet/vue-leaflet";
import { circleMarker } from "leaflet/dist/leaflet-src.esm" ;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

import JSZip from 'jszip';

import ROSLIB from "roslib" 

const moment = require('moment');
require('moment-timezone');

export default {
  name: "LiDAR" ,
    components: {
    LMap,
    LGeoJson,
    LTileLayer,
    LControlLayers
  },
   props: ['foldername', 'projectname'],
  data()  {
      
          return {
              frameNum: 25,
              startDegree : 0 , 
              endDegree : 180 ,
              stepDegree : 45 ,
        
              loaderStyleClass : "",
              statusMessage : "" , 
              lasViewerUrl : "" ,
              imgRecvCount : 0 , 
              gnssRecvCount : 0 ,
              imgSrc : "" ,
              map : undefined , 
              tileLayer : undefined ,
              useMapCache : true , 
              moment :undefined , 
              tileLayerClass: (url, options) => { console.log(url); console.log(options);} ,
              level : 0 , 
              zoom: 6,
              center: [34.723122805, 135.49628798833334],
              geoJsonList : [ ],
              gnss : undefined  ,
              ros :undefined,
              orderSub: undefined ,
              imageSub : undefined, 
              operationTopic : undefined, 
              // testSub : undefined ,
              lidarStateSub :undefined ,
              isShowConnection : false ,
              mapTileUrl : "/osm-{s}/{z}/{x}/{y}.png" ,
            //  url : "ws://192.168.179.101:9001/websocket" ,
             // url : "192.168.5.183" ,
			url : "momo-ltd.local",
			rosport : "9090",
		    dataserverport : "8443"
			
			// "ubuntu.local",//"localhost",
         }  ;
  }
  
  , beforeUnmounte: function (){
 
    }
       
  , mounted :async function( ){  
       this.lasViewerUrl = "/pwa/viewer/las/viewer.html?pers=8&coor=yxz";
       this.moment = require("moment");
       this.moment.tz.setDefault('Asia/Tokyo');
    //    this.showMap();
     this.connection ( );
    
    }
  ,methods:{   
  
  operation ( msg )
{
		 var dest = new ROSLIB.Message({
    data : msg   });
  this.operationTopic.publish(dest);
  },
  
  connection ( ){
      
      
    try{
          this.statusMessage = "LiDARを探しています。";
      this.loaderStyleClass =  "loading";
        
  this.ros = new ROSLIB.Ros({
    url : "wss://"+ this.url + ":" +this.rosport
  });
  
  
       
  this.ros.on('connection', () =>{
      
  	 this.$emit('show-flash',{ "message":  "LiDARと接続しました。","type": "success"}); 
     this.statusMessage = "接続中";
     this.loaderStyleClass =  "connect";
  });

this.operationTopic = new ROSLIB.Topic({
    ros : this.ros,
    name : '/control_in',
    messageType : 'std_msgs/String'
  });
    this. operationTopic.subscribe( (message) => {
  
        console.log(message.data); 
  });

  
this.imageSub  = new ROSLIB.Topic({
    ros : this.ros,
    name : '/image_raw/compressed',
    messageType : 'sensor_msgs/msg/CompressedImage'
  });
  
  this. imageSub.subscribe( (message) => {
  
        this.imgSrc =  "data:image/jpg;base64," + message.data; 
  });


this.lidarStateSub = new ROSLIB.Topic({
    ros : this.ros,
    name : '/lidar_status',
    messageType : 'std_msgs/String'
  });
  var filenameDate = this.moment().format("YYYY-MM-DD-HH-mm-ss_");
var gps=false;
 this. lidarStateSub.subscribe( (message ) => {

       console.log(message);
   
      if( message.data.includes("saved") ){
         
      var  filename =  message.data.replace( "saved: " ,"");
      const three =  window.document.getElementById( "three" );
      
        if( filename.includes(".txt")){
            gps = true;           
        }
      this.statusMessage = "処理中";
      this.loaderStyleClass =  "loading";
							
				if (!filename.includes("rotate.las"))
				if (filename.includes(".las") ){
							 
								filename = filename.replace('.las','.pcd');
														
							}
							
      try{
      const res =  fetch( "https://"+this.url + ":"+ this.dataserverport +"/"+filename ,{
        method: "GET"
        }).then( (res)=>{
             
             return res.blob();
								} ).then( (blob)=>{
					console.log("file:"+filename)
            try{
            if (filename.includes("rotate.las") ){
            
                three.contentWindow.loadFile (  new File([blob], filename ) );
                 this.uploadFile( filenameDate +filename , blob );
			}else 
            if (/* filename.includes(".las") || */ filename.includes(".pcd")||filename.includes(".jpg") || filename.includes(".txt") ){
              
                 this.uploadFile( filenameDate +filename , blob );
                  
            }
            }catch(error){ console.log(error); }
        });
        }catch(erro){
            console.log(erro);
        }
     } else 
     if( message.data.includes("operation is completed") ){
       
            if (!gps){
                
                try{
                const res =  fetch( "https://"+this.url +":"+ this.dataserverport + "/gps.txt" ,{
                method: "GET"
                }).then( (res)=>{
                    return res.blob();
                }).then( (blob)=>{
                   try{
                         this.uploadFile( filenameDate +"gps.txt" , blob );
                    }catch(er){
                         console.log(er);
                    }
                });
                } catch (err){
                    console.log(err);
                }
            }
            const b = three.contentWindow.getLASBlobData();
            this.uploadFile( filenameDate +"_scans_full.las" , b ,()=>{
                
                this.$emit('show-flash',{ "message": "計測が完了しました。","type": "success"}); 
                setTimeout( ()=>{ 
                 this.hideLiDAR()
                
                 } , 3000);
                
            });
            
     } else   if( message.data.includes("in operation") ){
        
         filenameDate = this.moment().format("YYYY-MM-DD-HH-mm-ss_");
         this.statusMessage = "計測中";
         this.loaderStyleClass =  "inoperate";
     }
     
});

  
this.orderSub = new ROSLIB.Topic({
    ros : this.ros,
    name : '/fix',
    messageType : 'sensor_msgs/msg/NavSatFix'
  });
 
 this. orderSub.subscribe( (message) => {
 
   // var data = message.data;
   this.gnss = message ;
     // console.log( message );
try{
     const geojsonFeature = { "json":
         { "type": "FeatureCollection",
        "features": [
          { "type": "Feature", "crs": { "properties": {} }, "properties": { "name": "test2" }, "geometry": { "type": "LineString", "coordinates": [ [  message.longitude,  message.latitude ], [  message.longitude,  message.latitude ] ] } 
     
     }
     ]}
     , "geojsonOptions": {
           style:  {
              weight: 20,
              color: '#00FF00'
            },
         onEachFeature: function onEachFeature(feature , layer ) {

          // does this feature have a property named popupContent?
            layer.bindPopup(message.longitude + " " + message.latitude );
        }
     } };
     // this.tileLayer.geoJSON(geojsonFeature).addTo(this.map);
     this.geoJsonList.pop();
     this.geoJsonList.push( geojsonFeature  );
  }catch (e){
      console.log(e);
  }
   //  console.log(this.geoJsonList); 
    });
  
    this.ros.on('error', (error )=> {
          
            this.$emit('show-flash',{ "message":  "接続エラー","type": "warning"});  
            this.statusMessage ="未接続";
            this.loaderStyleClass =   "disconnect";
          });

      this.ros.on('close', ()=> { 
            this.$emit('show-flash',{ "message":  "LiDARとの接続が切断されました。","type": "warning"});   
            this.statusMessage ="未接続";
            this.loaderStyleClass =  "disconnect";
      });
    
        } catch (e){
console.log(e);

    this.$emit('show-flash',{ "message":  "LiDARとの接続に失敗しました。","type":  "warning"});              
    this.statusMessage = "未接続";
    this.loaderStyleClass =  "disconnect";        
}   
  },
    hideLiDAR( ){
        
      this.$emit("show-lidar", false );
  },
  
   uploadFile(fname,fileData , suc  ){
     
      try{
         
             this.statusMessage = "データのアップロード";
             this.loaderStyleClass =  "uploading";

              fetch(         
                    "/tools/file/projects/pointcloud/"+  this.projectname  + '/' + this.foldername + '/folder/' + fname  , 
                    {
                        method: "POST", body: fileData ,
                        headers: { 'Content-type' : 'charset="utf-8"' }
                }).then( (res)=>{
                this.statusMessage = "接続中";
                this.loaderStyleClass =  "connect";
                    if (res.ok  ){
                    
                    this.$emit('show-flash',{ "message":  fname + " をアップロードしました。","type": "success"}); 
                    if(suc){
                        suc(); 
                    }
                    }else{ 
                    
                       this.$emit('show-flash',{ "message":  fname + " のアップロードに失敗しました。","type": "warning"}); 
                    }
                });
                
            }catch (e ){  console.log(e); }    
       
   }
  }
}

</script>

<style>
.dot{
    margin-left: 8px;
    width: 16px; 
    height: 16px; 
    border-radius: 50%; 
}
.connect {
    background-color: blue;
    animation: flash 1s linear infinite;
}
.disconnect {
  background-color: red;
   
}
.inoperation {
    animation: flash 1s linear infinite;
    background-color: green;
}
.uploading {
   animation: flash 1s linear infinite;
   background-color: green;
}
.loading {
   animation: flash 1s linear infinite;
   background-color: green;
}



@keyframes flash {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }
  
  
  100% {
    opacity: 1;
  }
}
body{
    
    background-color:transparent !important;
}

#three{
    background:black;
}

@media (max-width: 150px) {
    .lil-gui{
        display:none !important;
    }
    #three{
        background:rgba(0,0,0,0.7);
    }
}

@media (max-height: 300px) {
    .lil-gui{
        
        display:none !important;
    }
      #three{
          
        background:rgba(0,0,0,0.7);
    }
}

.lil-gui{

    left : 0;
}

</style>
