<template>
  <div v-show="project" class="col-12" style="
    z-index:999; 
    height: 100%;
    display: inline-block;
    position: absolute;
    background: white;
    left: 0;" 
    >
    <div class="col-12" style="margin-bottom: 2%;">
    <span class="btn btn-dark" v-on:click="showLiDAR"> LiDAR計測 </span>
    </div>
    
    <div class="card col-8 offset-2" style="margin-bottom:2%;">
    
    <span style="display: inline-block; position: absolute; width: 100%; top: 20%;">{{uploadFileName}}</span>
   
     <label
     :class="{ over: isDragOver }"
      @dragover.prevent="onDrag(true)"
      @dragleave.prevent="onDrag(false)"
      @drop.prevent="onDrop"
      class="fileuploadlabel input-item__label" v-on:click="showAddProjectForm=true" style="font-size:24px;  cursor: pointer;  font-size: 24px; line-height: 100px; z-index:9"> <input  @input="changeUploadinput" type="file" />  </label>
  
    </div>  

    <div v-for="filename in projectNames" :key="filename" class=" col-8 offset-2 float-start" style=" height:60px;  text-align: left; margin-bottom:2%;">
    
   <b class="col-10 float-start" style="overflow:hidden;" > {{ ( ()=>{  const list =  filename.split("/"); return list [ list.length-1];  })() }} </b>
   
   <span v-on:click="showFileSetting[filename] = (!showFileSetting[filename])"  class=" col-2 float-start" style="float: right; line-height: 12px; font-size: 24px; cursor: pointer; display: inline-block; height: 24px; text-align: right;">...</span>
   
   <div  v-show="showFileSetting[filename]">

   <div class="card col-6 float-end">
    <ul style="list-style: none;">
        <li>
        <a :href="'/tools/file/'+filename" target="_blank">ダウンロード</a>
        </li>
        <li v-show="filename.indexOf('.las')!==-1"  v-on:click="showPotree(filename)">
        <span class="a" style="cursor: pointer;" > 表示 </span>
       </li>
       
        <li v-show="filename.indexOf('fixed.las')!==-1|| filename.indexOf('full.las')!==-1 || filename.indexOf('rotate.las')!==-1"  v-on:click="showLASEditor(filename,'yxz')">
            <span class="a" style="cursor: pointer;" > 編集 </span>
        
        </li>
        
        <li v-show="filename.indexOf('fixed.las') == -1 && filename.indexOf('full.las') ==-1&& filename.indexOf('rotate.las') ==-1 &&filename.indexOf('.las')!==-1"  v-on:click="showLASEditor(filename,'xyz')">
            <span class="a" style="cursor: pointer;" > 編集 </span>
        
        </li>
        
      </ul>
    </div>
    </div>
   
   
   </div>
<!--
<span v-on:click="showNextFiles( Math.ceil( this.projectNames.length / bulkDispNum) )" style="cursor:pointer;">
 ▼次の{{ this.bulkDispNum }}件表示  {{   Math.ceil( this.projectNames.length / bulkDispNum) }} / {{   Math.ceil( this.projectNames.length / bulkDispNum ) }}
</span>
-->

</div>

</template>

<script lang="js" >
import { faCamera ,faFile  } from "@fortawesome/free-solid-svg-icons";

const moment = require('moment');

export default {
  name: "PointCloudFolder" ,
    components: {  } ,
    props: [ "project" ],
  data()  {
          return {
               showFileSetting : {},
              uploadFileName : "ファイルのアップロード",
              isDragOver : false , 
              bulkDispNum : 5 ,
              files : [] ,
              projectNames : [] , 
              showAddProjectForm : false , 
              addingProject :{
                  title : "" ,
                  description : ""
              },
              faCamera : faCamera , 
              faFile : faFile ,
              
         }  ;
  }
  , beforeUnmounte: function (){
  }
  ,  mounted  :async function(  ){  
    // https://iotcore-inte-api.azurewebsites.net/api/BulkGetFolderMeta?code=kgCiOAju3qIZcXtwTYFtvzAj2sVGZdcxmhyTKo-XaaJ4AzFuFol9AQ==
     //  await this.showFolder ();
     },
  watch: {
       project : function( newprop, oldprop){
         this.showFolder();
      
         console.log(newprop);
       }
    }
  ,methods:{  

      onDrop(e){
            this.changeUploadinput(e);
        },
         onDrag (state){
             this.isDragOver = state;
        },
        async  changeUploadinput(e){
       
             const file = e.dataTransfer.files[0] ;
        
             this.uploadFileName = file.name;
             try{
             if ( 800000000 < file.size ){
                
                
                this.emit('show-flash',{ "message": "ファイルのサイズは400MB以下でお願いい致します。","type": "warning"});  
                   
                return ;
             }
          
              this.$emit('show-flash', true);
             const folder = "/projects/pointcloud/"+ this.$route.params.projectname +"/" +this.project.title + "/folder/";
             console.log (  "/tools/file/"+ folder + this.uploadFileName  ) ;
             const res = await fetch( 
             
                "/tools/file/"+ folder + this.uploadFileName , 
                {
                     method: "POST", body: file ,
                     headers: { 'Content-type' : 'charset="utf-8"' }
                }
            );
            
            if (res.ok ){
                   
                   this.$emit('show-flash',{ "message": "アップロードしました。","type": "success"}); 
                   this.uploadFileName = "ファイルのアップロード" ; 
                   window.location.reload();
            } else {
                  this.showFolder();
                   this.$emit('show-flash',{ "message": "ファイルのアップロードに失敗しました。","type": "warning"});  
            }
            
            }catch (e){
                
                this.$emit('show-flash',{ "message": "ファイルのアップロードに失敗しました。","type": "warning"});  
               console.log(e) ; 
            }
                         this.$emit('show-flash', false);
         },
         async showFolder (){
             
            try {
            var res  ;
            var r = "dirname=projects/pointcloud/"+ this.$route.params.projectname +"/" +this.project.title+"/folder/&flat=false"
            var code = "";
            if(process.env.VUE_APP_API_CODE =="" ){
               code = "?";
            } else {
               code = process.env.VUE_APP_API_CODE + "&";
            }  
            res = await fetch (process.env.VUE_APP_API_URL +"GetFileByHierarchyV2" +  code + r  );    
        
         const json = await res.json();   
         this.projectNames =  json.filenames;
      
        
        }catch (e) {
             
             console.log( e );
         }
  
         },
         async updateProject (project) {
             this.editMode= false ;
             if ( confirm("編集内容を保存しますか？") ){
                try{
                let res = await fetch (process.env.VUE_APP_API_URL + 'CreateOrUpdateFolderV2'+process.env.VUE_APP_API_CODE ,
                 {
                  method : "POST",
                  headers: {  'Content-Type': 'application/json' },
                  body :JSON.stringify( {
                     path :  project.path ,
                     info : {
                        type : project.type ,
                        title : project.title ,
                        description : project.description ,  
                        created_at : project.created_at
                    }
                  } )
                } );      
             
              if(res.status===200){
                  
                  this.$emit('show-flash',{ "message": "更新しました。","type": "success"});                     
              } else {
                    
                 this.$emit('show-flash',{ "message": "更新出来ませんでした。","type": "warning"});     
              }
            } catch(e){
                 this.$emit('show-flash',{ "message": "更新出来ませんでした。","type": "warning"});     
            }
                    
            }
               this.showFolder(); 
                         
         } ,
         async deleteProject ( project ){
           //  alert( JSON.stringify(project) );
             if ( confirm("本当に削除しますか？") ) {
              try{
                    project.showSubMenu = false ;
               const res= await fetch (process.env.VUE_APP_API_URL + "DeleteFolderV2" +process.env.VUE_APP_API_CODE  , {
                  method : "DELETE",
                  headers: {  'Content-Type': 'application/json' },
                  body :JSON.stringify( { "path" : project.path } )
                });    
                const err = await res.json();
                if ( err.error === 0  ){
                    
                     this.$emit('show-flash',{ "message": project.title + "を削除しました。","type": "success"});     
                    this.showFolder();
                } else {
                     this.$emit('show-flash',{ "message": project.title + "の削除ができませんでした。。","type": "warning"});     
                }
                }catch (e){
                     this.$emit('show-flash',{ "message": project.title + "の削除ができませんでした。。","type": "warning"});   
                } 
          
            }
            
         },
         async convertToPotree ( filename ){
            
              this.$emit('show-flash',{ "message": "表示用データの作成中","type": "success"});     
             try{
                let res = await fetch ( "/tools/command/potreeconv/single" ,
                 {
                  method : "POST",
                  headers: {  'Content-Type': 'application/json' },
                  body :JSON.stringify({ "filepath": filename }  )
                } );      
             
              //    this.$emit('show-flash',{ "message": "表示用データを作成しました。","type": "success"});                               
              if(res.status!==200){
           
                 this.$emit('show-flash',{ "message": "表示用データの作成が出来ませんでした。","type": "warning"});     
              }
            } catch(e){
             
                 this.$emit('show-flash',{ "message": "表示用データの作成が出来ませんでした。","type": "warning"});     
            }
           
             
         },
          showLiDAR ( ){
                 this.$emit('show-lidar', true ) ;
         },
          async showPotree ( filename ){
          
            await this.convertToPotree(filename);
              
           const potreepath = filename.replace( "/folder/","/potree/folder/" ).replace(/\./g,'_');
           this.$emit('show-potree', { path : potreepath } ) ;
         },
         showLASEditor ( filename , coor ){
          
            const editorpath= "/pwa/viewer/las/editor.html?pers=20&coor="+coor+"&fileurl=/tools/file/" + filename ;
            window.open(editorpath);
            
            // this.$emit('show-laseditor', { path : editorpath } ) ;
         },
         async showNextFiles ( pos  ){
         
            var npos = this.bulkDispNum * ( pos +1 ) ; 
            npos = ( npos > this.projectNames.length ) ?  this.projectNames.length : npos; 
            const fs = [] ;
            for  ( var cpos = this.bulkDispNum * pos  ;   cpos < npos ; cpos++ ){
                if(  cpos >= npos ){
                   break;
                 } 
            
                fs.push( this.projectNames[cpos] );
            }    
             
    },
         moment (text){
           
           return moment(text);  
         },
          async addProject (){
     
            try{
                
                let res = await fetch (process.env.VUE_APP_API_URL + 'CreateFolderV2'+process.env.VUE_APP_API_CODE ,
                 {
                  method : "POST",
                  headers: {  'Content-Type': 'application/json' },
                  body :JSON.stringify( {
                     path :  "projects/pointcloud/" + this.$route.params.foldername + "/" + this.addingProject.title ,
                     info : {
                        type : this.addingProject.type  ,
                        title : this.addingProject.title ,
                        description : this.addingProject.description 
                    } } )
                } );      
                let resp = await res.json();
                if(resp.error === 0){
                    
                    this.$emit('show-flash',{ "message":"プロジェクトを追加しました。","type": "success"});     
                    this.showFolder();
                    this.showAddProjectForm = false ;
                } else if (resp.error === 1) {
                       this.$emit('show-flash',{ "message":"プロジェクトのタイトルが存在します。","type": "warning"});     
                } else {
                    
                      this.$emit('show-flash',{ "message":"プロジェクトの追加に失敗しました。","type": "warning"});     
                }
            } catch (e){
                
                this.$emit('show-flash',{ "message":"プロジェクトの追加に失敗しました。","type": "warning"});     
            }
          }
    }
}

</script>

<style>
label > input {
  display: none;
}

.over {
 background: rgba(0,0,0,0.1);
}

label {
  padding: 0 1rem;
} 

.fileuploadlabel::after {
  content: '+';
  font-size: 1rem;
  color: #888;
  padding-left: 1rem;
}

body{
    
    background-color:transparent !important;
}

#three{
    background:black;
}

@media (max-width: 150px) {
    .lil-gui{
        display:none !important;
    }
    #three{
        background:rgba(0,0,0,0.7);
    }
}

@media (max-height: 300px) {
    .lil-gui{
        
        display:none !important;
    }
      #three{
          
        background:rgba(0,0,0,0.7);
    }
}
.closeBtn{
  color:gray;
  font-size : 32px; 
  position: absolute;
  right: 4%;
  margin-top: 1%;
  cursor: pointer;
}
.lil-gui{

    left : 0;
}
.a {
     font-weight: bold; 
     color: dimgray !important;
}
</style>
